import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Order_By,
  useGetAllCategoryKnowledgeQuery,
  useGetCourseByCategoryQuery,
} from '@/generated/graphql';
import { LazyImage } from '../shared';
import Link from 'next/link';
import { UrlLink } from '@/configs/urlLink';
import _format from '@/utils/format';
import { LeftArrowIcon1, RightArrowIcon1 } from '@/assets/icons';
import HomeCardPlaylist from './HomeCardPlaylist';
import Slider, { Settings } from 'react-slick';
import HomeCardPlaylistFeature from './HomeCardPlaylistFeature';

const KnowledgePlaylistSection = () => {
  const { data: dataPlaylistCategory } = useGetAllCategoryKnowledgeQuery({
    variables: {
      orderBy: [
        {
          playlist_order: Order_By.AscNullsLast,
        },
      ],
    },
  });

  const filteredCategoryNotEmpty = dataPlaylistCategory?.course_category.filter(
    outerItem =>
      outerItem.course_course_categories.some(
        innerItem => innerItem?.course?.lessons?.length || 0 > 0,
      ),
  );

  const [currentCategoryId, setcurrentCategoryId] = useState<string>(
    filteredCategoryNotEmpty?.[0]?.name || '',
  );

  useEffect(() => {
    setcurrentCategoryId(filteredCategoryNotEmpty?.[0]?.id ?? '');
  }, [filteredCategoryNotEmpty?.[0]?.id]);

  const { data: dataListCourse, loading } = useGetCourseByCategoryQuery({
    variables: {
      where: {
        course_course_categories: {
          course_category_id: {
            _eq: currentCategoryId,
          },
        },
      },
      orderBy: [
        {
          order: Order_By.AscNullsLast,
        },
      ],
    },
  });

  const settingsMobile: Settings = {
    dots: false,
    infinite: true,
    arrows: false,
    variableWidth: true,
  };

  const listCourse = useMemo(() => {
    return dataListCourse?.course.filter(item => item.lessons.length > 0);
  }, [currentCategoryId, dataListCourse]);

  const featureCourse = useMemo(() => {
    return listCourse?.[0];
  }, [listCourse]);

  const customSlider = useRef<Slider>(null);
  const goToPrev = () => customSlider?.current?.slickPrev();
  const goToNext = () => customSlider?.current?.slickNext();

  const lengSlide = (listCourse?.length || 0) - 1;

  return (
    <div className="bg-white">
      <div className="container py-6">
        <div className="bg-white">
          <div className="border-neutral-grayLight flex flex-wrap items-center justify-between px-2 md:p-3">
            <div className="md:pb-4">
              <h2 className="font-header py-4 text-2xl font-semibold md:py-0">
                Video Kiến Thức
              </h2>
            </div>
            <div className="relative w-full">
              <div className="no-scrollbar w-full overflow-x-scroll pb-2 text-center">
                <ul className="flex">
                  {filteredCategoryNotEmpty?.map((item, index) => (
                    <li className="" key={index}>
                      <button
                        onClick={() => setcurrentCategoryId(item.id)}
                        className={`inline-block rounded-t-lg border-b-2 px-4 py-2 ${
                          currentCategoryId === item.id
                            ? 'border-primary-eastern text-primary-eastern'
                            : 'border-transparent'
                        }`}
                      >
                        <p
                          className={`text-PC-Button-Medium md:text-PC-Button-Medium w-max text-[#020509] ${
                            currentCategoryId === item.id
                              ? 'text-primary-eastern'
                              : ''
                          }`}
                        >
                          {item.name}
                        </p>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {featureCourse ? (
            <>
              <Link
                className="hover:text-primary-eastern flex py-2 md:px-3"
                href={`${
                  UrlLink.knowledge.knowledge
                }/${_format.convertTitleToSlug(
                  featureCourse?.lessons?.[0]?.name || '',
                )}-${featureCourse?.lessons?.[0]?.id}`}
                aria-label="Link to course detail page"
              >
                <div className="hidden md:grid md:w-full md:grid-cols-2">
                  <div className="relative h-[236px] w-full md:h-[332px]">
                    <LazyImage
                      src={featureCourse?.thumbnail_url || ''}
                      alt="Thumbnail course"
                      fill
                      sizes="(max-width: 768px) 50vw, (max-width: 1024px) 30vw, 25vw"
                      className="object-fit"
                    />
                  </div>
                  <div className="flex w-full flex-col gap-3 py-8 md:px-4 lg:px-6">
                    <h3 className="text-PC-Display-H5 md:text-PC-Display-H3 font-header">
                      {featureCourse?.name}
                    </h3>
                    <p className="font-sans text-[14px] font-semibold text-[#020509] lg:leading-[17.6px]">
                      {featureCourse?.lessons_aggregate?.aggregate?.count}{' '}
                      Videos
                    </p>
                  </div>
                </div>
              </Link>
              <div className="w-full md:hidden">
                <HomeCardPlaylistFeature
                  data={featureCourse}
                  isLoading={loading}
                  nameFirstVideo={''}
                  idFirstVideo={0}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="relative w-full py-6 md:px-1">
          {listCourse?.length ? (
            <div>
              {listCourse?.length ? (
                <div>
                  <div className="b">
                    <Slider
                      {...{
                        ...settingsMobile,
                        infinite: lengSlide < 4 ? false : true,
                      }}
                      ref={customSlider}
                    >
                      {listCourse
                        ?.filter(item => item.id != featureCourse?.id)
                        ?.map((item, index) => {
                          return (
                            <div key={item?.id} className="!w-[288px] p-2">
                              <HomeCardPlaylist
                                key={index}
                                data={item}
                                isLoading={loading}
                                nameFirstVideo={item.lessons?.[0]?.name || ''}
                                idFirstVideo={item.lessons?.[0]?.id || 0}
                              />
                            </div>
                          );
                        })}
                    </Slider>
                    <div className="hidden md:block">
                      <div
                        className={`left-[-50px] top-1/2  -translate-y-1/2 md:absolute ${
                          Number(listCourse?.length ?? 0) > 5
                            ? 'block'
                            : 'hidden'
                        }`}
                      >
                        <button onClick={goToPrev} className="cursor-pointer">
                          <LeftArrowIcon1 />
                        </button>
                      </div>
                      <div
                        className={`right-[-50px] top-1/2  -translate-y-1/2 md:absolute ${
                          Number(listCourse?.length ?? 0) > 5
                            ? 'block'
                            : 'hidden'
                        }`}
                      >
                        <button onClick={goToNext}>
                          <RightArrowIcon1 className="cursor-point" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default KnowledgePlaylistSection;
